/* Banner component styles */
.banner {
  background-color: rgb(21, 99, 118);
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 2rem;
  width: 100vw;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  z-index: 999;
  font-family: arial;
}

.banner-message {
  text-align: center;
  margin: 0;
  font-size: 18px;
}

.banner-close-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.banner-close-button:hover {
  background-color: #c0392b;
}

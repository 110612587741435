.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: transparent;
  /* height: 15vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  /* top: 4rem; */
  z-index: 999;
  width: 100vw;
}

.navbar.active {
  background: #231f20;
  transition: 0.4s ease-in-out;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80px; */
  width: 100vw;
  /* background: cyan; */
  /* max-width: 1500px; */
}

.navbar-logo {
  color: #fff;
  /* justify-self: start; */
  /* margin-left: 20px; */
  cursor: pointer;
  /* text-decoration: none; */
  /* font-size: 2rem; */
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  /* display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 2px; */
  display: flex;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
  /* margin-right: 1.5rem; */
}

.nav-item {
  /* height: 14px; */
  display: flex;
  align-items: center;
}

.nav-links {
  position: relative;
  color: #fff;
  display: flex;
  font-size: 1.1rem;
  font-weight: 400;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.8rem;
  height: 95%;
}

.nav-links::before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 0 4px;
  border-bottom: 2px solid #ff7300;
  transform: scaleX(0);
  transform-origin: top left;
  transition: all 0.3s ease-out;
}
.nav-links:hover::before {
  transform: scaleX(1);
}
.fa-bars {
  color: #ff7300;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* css for small screens */
@media screen and (max-width: 1050px) {
  .navbar {
    /* height: 15vh; */
    /* background-color: red; */
  }

  .navbar-container {
    justify-content: space-around;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-item {
    /* margin-right: 1rem; */
    width: 100%;
    text-align: end;
  }

  .nav-item:not(:last-child) {
    height: fit-content;
    box-sizing: border-box;
    border-bottom: 1px solid white;
  }
  ul.nav-menu li.nav-item {
    padding: 1rem;
    padding-right: 2.3rem;
  }
  ul.nav-menu li.nav-item:first-child {
    padding-top: 2rem;
  }
  ul.nav-menu li.nav-item:last-child {
    padding-bottom: 1.2rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    grid-gap: 2px;
    /* width:60vw; */
    /* height: 67vh; */
    position: absolute;
    top: 52px;
    right: -100%;
    opacity: 8;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    display: flex;
    align-items: flex-end;
    height: fit-content;
    box-sizing: border-box;
    background: #231f20;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    /* z-index: 1; */
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    width: 68vw;

    /* align-items: ; */
  }
  .nav-links {
    height: auto;
    border-bottom: none;
    transform: none;
  }
  a.nav-links {
    font-size: 1rem;
    text-align: end;
    padding: 0;
    /* padding-right: 3rem; */
    width: 100%;
    display: table;
    border-bottom: none;
  }

  .nav-item:hover {
    /* background-color: #ff7300; */
    color: #fff;
    border-radius: 0;
  }
  .nav-item:first-child:hover {
    border-top-left-radius: 2rem !important;
    background-color: #ff7300;
    color: #fff;
    border-radius: 0;
  }
  .nav-item:last-child:hover {
    border-bottom-left-radius: 2rem !important;
    background-color: #ff7300;
    color: #fff;
    border-radius: 0;
    width: 77%;
  }

  /* .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 25%);
  } */

  .menu-icon {
    display: block;
    justify-content: center;
    align-items: center;
    /* background: yellow; */
    /* position: absolute;
    top: 0px;
    right: 5px;
    transform: translate(-100%, 55%); */
    font-size: 1.8rem;
    cursor: pointer;
    margin-left: 0.7rem;
    /* height: 55px; */
  }

  .fa-times {
    color: #ff7300;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: flex;
    text-align: center;
    /* justify-content: center; */
    /* margin: 2rem auto 2rem 1rem; */
    margin: 1rem auto 2rem auto;
    /* margin-right: 3rem; */
    border-radius: 4px;
    width: 50%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

@media screen and (max-width: 600px){
  .navbar-container {
    justify-content: space-between;
    width: 85%;
  }
}

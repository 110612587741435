@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap");
.App {
  text-align: center;
}

.navbar {
  display: flex;
  /* justify-content: space-around; */
  position: sticky;
  /* border-bottom: 1px solid #dbd9d3;
  border-top: 10px solid #359db6; */
}

.coinImg {
  width: 25%;
  height: 350px;
  object-fit: "contain";
}

@media screen and (max-width: 1180px) {
  .coinImg {
    width: 60%;
    height: 350px;
    object-fit: "contain";
  }
}

@media screen and (max-width: 850px) {
  .coinImg {
    width: 70%;
    height: 350px;
    object-fit: "contain";
  }
}

@media screen and (max-width: 600px) {
  .coinImg {
    width: 80%;
    height: 250px;
    object-fit: "contain";
  }
}
@media screen and (max-width: 500px) {
  .coinImg {
    width: 80%;
    height: 150px;
    object-fit: "contain";
  }
}

.navLogo {
  width: 8%;
  padding: 1%;
  float: left;
}

.navButton {
  font-family: "arial";
  position: sticky;
  font-size: 14px;
  font-weight: 900;
  color: azure;
  border: none;
  background: none;
  padding-top: 35px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.navicon {
  width: 15px;
}

.navButton:hover {
  border-bottom: 3.5px solid #ff9c00bf;
  cursor: pointer;
}

.navButton:active {
  border-bottom: 3.5px solid #ff9c00bf;
}

.navLinks-rightButtons {
  color: grey;
}

.footer {
  background-color: rgb(243, 242, 240);
  border-bottom: 10px solid #359db6;
  display: block;
}

.footerLogo {
  width: 5%;
  margin-top: 1%;
}

.footer-h4 {
  color: grey;
}

.footer-text {
  color: grey;
  font-size: 90%;
  font-style: inherit;
  font-weight: inherit;
}

.footer-link {
  color: #359db6;
  font-weight: bold;
  text-decoration: none;
  padding-bottom: 1%;
}

.homesection1 {
  height: "20%";
}

.homebox1 {
  background-color: rgb(226, 226, 226);
  margin: 5px 5px;
  text-align: left;
}

.homebox1-h1 {
  color: #3d7285;
  padding-left: 20px;
}

.homebox1-p {
  font-size: larger;
  padding: 20px;
}
/* 
.homebox2 {
  background-image: url("./images/banner-1.jpg");
  background-size: 100%;
  background-repeat:no-repeat;
} */

.banner1 {
  width: 100%;
}

.homebox2-box {
  width: 340px;
  height: 170px;
  background-color: white;
  border-radius: 10px;
  padding-top: 200px;
  padding-left: 390px;
  opacity: 0.8;
  text-align: left;
  padding: 0 10px;
}

.homebox2-p1 {
  color: grey;
  font-size: 36px;
  margin: 0;
  padding-left: 7px;
  padding-top: 10px;
}

.homebox2-p2 {
  font-size: 15px;
}

.homebox2-btn {
  background-color: rgb(255, 174, 0);
  opacity: 1;
  border: none;
  margin-left: 250px;

  border-radius: 5px;
}

.homebox2-btn-a {
  color: white;
  text-decoration: none;
}

.homesection2 {
  margin: 4% 0;
  display: inline-flex;
}

.homesection2-img {
  margin-right: 2%;
  padding: 30px;
}
.homesection2-h1 {
  color: #3d7285;
}
.homesection2-p {
  width: 700px;
  text-align: left;
  margin-left: 5%;
  font-size: 20px;
}

.homesection3 {
  text-align: left;
  margin: auto;
  width: 77%;
}

.homesection3-h1 {
  color: #3d7285;
}

.homesection3-p {
  font-size: 20px;
}

.homesection4 {
  display: flex;
}

.homesection4-tiles {
  padding: 30px;
  width: 50%;
  text-align: left;
  margin: auto;
}

.homesection4-tiles img {
  width: 80%;
}

.homesection4-tiles h4 {
  color: grey;
}

.homesection4-tiles p {
  color: grey;
}

.aboutpage {
  margin: 1%;
}
.aboutbanner1 {
  width: 100%;
  display: inline-block;
  height: 400px;
}

.aboutbanner2 {
  width: 100%;
  display: inline-block;
  height: 400px;
}

.color {
  color: #359db6;
}

.aboutsection2 {
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.aboutsection3 {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 80%;
}

.aboutsection3-h1 {
  color: grey;
  border-left: 4px solid #ff9c00bf;
  padding-left: 4px;
}

.aboutsection3-imgs {
  width: 200px;
}

.emptygrid {
  margin-top: 20px;
  margin-bottom: 20px;
}

.aboutsection4 {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 80%;
}

.aboutsection4-img {
  display: block;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.colorGrey {
  color: grey;
}

.blueLink {
  color: #359db6;
  text-decoration: none;
}

.insightsContainer {
  margin-top: 15px;
}

.insightSections {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 80%;
}

.insightSection2-txt {
  font-family: inherit;
  font-size: 20px;
}

.spacing {
  margin-top: 15px;
  margin-bottom: 15px;
}

.insightsection3 {
  margin-top: 15px;
}

.registrybanner {
  max-width: 100%;
  margin-bottom: 40px;
}

.registrySection2 {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-left: 5%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.plainlink {
  text-decoration: none;
  color: grey;
}

.registrationcontainer-txt {
  margin-top: 30px;
  margin-bottom: 30px;
}
#root
  > div
  > div.classes\.root
  > div
  > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > a
  > div
  > button {
  background: none !important;
}
#root
  > div
  > div.classes\.root
  > div
  > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > a
  > div
  > button:hover {
  color: #fff !important;
  background: #ff7300 !important;
}
